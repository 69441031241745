<template lang="pug">
include common.pug
div
    .alert.alert-warning(v-if="err") {{err.message}}
    form.card(@submit.prevent="saveCollectionWithFacts")
        h4.card-header(v-if="collection") {{"Manage aggregated fact data for "+collection.name}}
        .card-body
            .mb-2(class="form-group" style="position:relative;width:100%")
                h5 Upload collection-level aggregated fact data
                input.form-control(type="file" ref="factCollectionFile" :disabled="donorFileOK || donorFileError"
                  @change="factCollectionFileChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                small="An Excel (.xslx) file containing aggregated fact data specified per collection"
                div.btn(style="position:absolute;top:33px;left:calc(100% - 40px)" @click="resetCollection()")
                    i.pi.pi-times(style="color:darkgray")
                div.text-success.mx-sm-3(v-if="!collectionFileOK && !collectionFileError" style="visibility:hidden") XXX
                div.text-success.mx-sm-3(v-if="collectionFileOK") The data in the collection-level fact file is correct ({{ totalFactsFromData }} aggregated fact {{pluralR(totalFactsFromData)}})
                div.text-danger.mx-sm-3(v-if="collectionFileError")
                    span The data in the collection-level fact file is incorrect
                    li.text-danger.mx-sm-3(v-for="e in collectionFileErrors") {{ e }}
            .mb-2(class="form-group" style="position:relative;width:100%;margin-top:15px")
                h5 Upload donor-level aggregated fact data
                input.form-control(type="file" ref="factDonorFile" :disabled="collectionFileOK || collectionFileError"
                  @change="factDonorFileChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                small="An Excel (.xslx) file containing aggregated fact data specified per donor"
                div.btn(style="position:absolute;top:33px;left:calc(100% - 40px)" @click="resetDonor()")
                    i.pi.pi-times(style="color:darkgray")
                div.text-success.mx-sm-3(v-if="!donorFileOK && !donorFileError" style="visibility:hidden") XXX
                div.text-success.mx-sm-3(v-if="donorFileOK") The data in the donor-level fact file is correct ({{ totalDonorRecords }} donor {{pluralR(totalDonorRecords)}} to be converted into {{ totalFactsFromDonors }} aggregated fact {{pluralR(totalFactsFromDonors)}})
                div.text-danger.mx-sm-3(v-if="donorFileError")
                    span The data in the donor-level fact file is incorrect
                    li.text-danger.mx-sm-3(v-for="e in donorFileErrors") {{ e }}
            .mb-2(style="margin-top:20px")
                button.btn.btn-primary(style="width:170px",type="submit", :disabled="!canSubmit()") Save changes
                span.text-danger.mx-sm-3(v-if="err") {{err.message}}
                span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
            .mb-2
                button.btn.btn-danger(style="width:170px", type="submit", :disabled="totalFacts === 0", @click.prevent="deleteFacts") Delete fact data
                span.text-success.mx-sm-3(v-if="deleteSuccess") Fact data has been deleted!
    .card.mb-4(style="margin-top:20px")
        h4.card-header(v-if="collection") {{"Fact data for "+collection.name}}
        .card-body
            .mb-2
                h5(v-if="totalFacts != null && totalFacts !== 0") Currently uploaded {{totalFacts}} aggregated fact {{pluralR(totalFacts)}}
                h5(v-if="totalFacts == null || totalFacts === 0") No aggregated fact records uploaded
                table.table.table-striped(v-if="facts != null && facts.length > 0")
                    thead: tr
                        th(scope="col") #
                        th(scope="col") Age Range
                        th(scope="col") Sex
                        th(scope="col") Material
                        th(scope="col") Diagnosis
                        th(scope="col") #Donors
                        th(scope="col") #Samples
                    tbody: tr(v-for="fact in facts" v-if="facts != null")
                        td {{ facts.indexOf(fact)+1 }}
                        td {{ fact.age_range }}
                        td {{ fact.sex }}
                        td {{ fact.sample_type }}
                        td {{ fact.disease }}
                        td {{ fact.number_of_donors }}
                        td {{ fact.number_of_samples }}


</template>

<script>
import {getCollection, patchCollection, getFacts, deleteFacts} from "../api";
import readXlsxFile, {Integer} from "read-excel-file";
import "core-js/proposals/array-grouping-v2";

export default {
  data() {
        return {
            collection: null,
            isChanged: false,
            err: null,
            dirty: false,
            collectionFileOK: false,
            donorFileOK: false,
            collectionFileError: false,
            collectionFileErrors: [],
            donorFileError: false,
            donorFileErrors: [],
            exceedSamples: false,
            // exceedDonors: false,
            totalSamples: 0,
            totalDonors: 0,
            saveSuccess: false,
            deleteSuccess: false,
            totalFacts: null,
            facts: null,
            totalDonorRecords: null,
            totalFactsFromDonors: null,
            totalFactsFromData: null,

            ageRangeTypesMapping: {
              'Young Adult' : { min: 18, max: 24 },
              'Middle-aged' : { min: 45, max: 64 },
              'Aged (>80 years)' : { min: 80, max: 122 },
              'Newborn' : { min: 0, max: 1 },
              'Adolescent' : { min: 13, max: 17 },
              'Aged (65-79 years)' : { min: 65, max: 79 },
              'Adult' : { min: 25, max: 44 },
              'Infant' : { min: 0, max: 1 },
              'Child' : { min: 2, max: 12 },
			  '*' : { min: 0, max: 122 }
          }


        }
    },
    created() {
        console.log("created:"+this.$route.params.collection_id);
        getCollection(this.$route.params.collection_id).then((collection) => {
            this.collection = collection;
            console.log(this.collection);
        });
        getFacts(this.$route.params.collection_id).then(facts => {
          // console.log("getting facts");
          // console.log(facts);
          facts.forEach(f => {
            let parts = f.disease.split(":");
            f.disease = parts[parts.length-1];
          });
          this.totalFacts = facts.length;
          this.facts = facts;
        });
    },
    beforeRouteUpdate(to, from, next){
      console.log("beforerouteupdate:"+this.$route.params.collection_id);
        getCollection(this.$route.params.collection_id).then((collection) => {
          this.collection = collection;
        })
        next()
    },
    methods: {

      factCollectionFileChange() {
        this.saveSuccess = false;
        this.deleteSuccess = false;
        this.readCollectionExcelFile(this.$refs.factCollectionFile.files[0]);
      },
      factDonorFileChange() {
        this.saveSuccess = false;
        this.deleteSuccess = false;
        this.readDonorExcelFile(this.$refs.factDonorFile.files[0]);
      },
      getFittingAgeRanges() {
        let age_high = this.collection.age_high;
        let age_low = this.collection.age_low;
        let fittingRanges = [];
        Object.entries(this.ageRangeTypesMapping).forEach(([key, value]) => {
              if (!(value.max < age_low || value.min > age_high)) {
                  fittingRanges.push(key);
              }
            }
          );
        return fittingRanges;
      },

      getFittingAges() {
        let fittingAges = [];
        for (let i = this.collection.age_low; i <= this.collection.age_high; i++) {
          fittingAges.push(i.toString());
        }
        return fittingAges;
      },

      getFittingDiseases() {
        let diseases = this.collection.diagnosis_available;
        let fittingDiseases = [];
        diseases.forEach(d => {
          let parts = d.split(":");
          fittingDiseases.push(parts[parts.length-1]);
        });
        console.log(fittingDiseases);
        return fittingDiseases;
      },

      readCollectionExcelFile(file) {

        const schema = {
          'age_range': {
            prop: 'age_range',
            type: String,
            required: true,
            oneOf: this.getFittingAgeRanges()
          },
          'sex': {
            prop: 'sex',
            type: String,
            required: true,
            oneOf: this.collection.sex
          },
          'material_type': {
            prop: 'sample_type',
            type: String,
            required: true,
            oneOf: this.collection.materials
          },
          'disease': {
            prop: 'disease',
            type: String,
            required: true,
            oneOf: this.getFittingDiseases()
          },
          'number_of_donors': {
            prop: 'number_of_donors',
            type: Integer,
            required: true
          },
          'number_of_samples': {
            prop: 'number_of_samples',
            type: Integer,
            required: true
          }
        };

        readXlsxFile(file, { schema, transformData(data) {
            let firstRow = true;
            let indexSex = -1;
            let indexMaterial = -1;
            data.forEach(d => {
              if (firstRow) {
                indexSex = d.indexOf("sex");
                indexMaterial = d.indexOf("material_type");
                firstRow = false;
              } else {
                if (indexSex !== -1) d[indexSex] = d[indexSex].toString().toUpperCase();
                if (indexMaterial !== -1) d[indexMaterial] = d[indexMaterial].toString().toUpperCase();
              }
            });
            return data;
          }
        }).then((res) => {

          this.collection.collectionFacts = res.rows;
          if (res.errors.length === 0 && res.rows.length !== 0) {
            this.collectionFileOK = true;
            this.collectionFileError = false;
            this.collectionFileErrors = [];
            this.checkTotals(res.rows);
            if (this.exceedSamples) {
              this.collectionFileError = true;
              this.collectionFileOK = false;
              this.collectionFileErrors = [];
              this.handleErrors(res, this.collectionFileErrors, schema);
            }
          }
          else {
            this.collectionFileError = true;
            this.collectionFileOK = false;
            this.collectionFileErrors = [];
            this.handleErrors(res, this.collectionFileErrors,schema);
          }
        });

      },
      handleErrors(res,collection,schema) {
        if(res.errors.length === 0) {
          if (this.exceedSamples) {
            if (this.exceedSamples) {
              collection.push("The total number of samples from the fact table (" + this.totalSamples +
                  ") exceeds the number specified for the collection (" +
                  this.collection.size + ")")
            }
            // if (this.exceedDonors) {
            //   collection.push("The total number of donors from the fact table (" + this.totalDonors +
            //       ") exceeds the number specified for the collection (" +
            //       this.collection.number_of_donors + ")")
            // }
          } else
            collection.push("Attribute row is missing or there were no correct columns specified")
        } else {
          res.errors.forEach(e => {
            if (e.error === "required") {
              collection.push("Row "+e.row+ ": Missing value for the required column "+e.column);
            }
            else if (e.error === "invalid") {

              let range = e.column === "age" ? this.collection.age_low + "-" + this.collection.age_high
                  : schema[e.column].oneOf;
              collection.push("Row "+e.row+ ": The value for column "+e.column + " ("+ e.value +
                  ") does not correspond to the set/range of values defined for a collection ("+range+")");
            } else
              collection.push("Row: "+e.row+ " Column: "+e.column+ " Error: "+ e.error);
          });
        }

      },

      readDonorExcelFile(file) {

        const schema = {
          'donor_id': {
            prop: 'donor_id',
            type: String,
            required: true
          },
          'age': {
            prop: 'age',
            type: String,
            required: true,
            oneOf: this.getFittingAges()
          },
          'sex': {
            prop: 'sex',
            type: String,
            required: true,
            oneOf: this.collection.sex
          },
          'material_type': {
            prop: 'sample_type',
            type: String,
            required: true,
            oneOf: this.collection.materials
          },
          'disease': {
            prop: 'disease',
            type: String,
            required: true,
            oneOf: this.getFittingDiseases()
          },
          'number_of_samples': {
            prop: 'number_of_samples',
            type: Integer,
            required: true
          }
        };

        readXlsxFile(file, { schema, transformData(data) {
            let firstRow = true;
            let indexSex = -1;
            let indexMaterial = -1;
            let indexAge = -1;
            data.forEach(d => {
              if (firstRow) {
                indexSex = d.indexOf("sex");
                indexMaterial = d.indexOf("material_type");
                indexAge = d.indexOf("age");
                firstRow = false;
              } else {
                if (indexAge !== -1) d[indexAge] = d[indexAge].toString();
                if (indexSex !== -1) d[indexSex] = d[indexSex].toString().toUpperCase();
                if (indexMaterial !== -1) d[indexMaterial] = d[indexMaterial].toString().toUpperCase();
              }
            });
            return data;
          }
        }).then((res) => {

          if (res.errors.length === 0 && res.rows.length !== 0) {
            this.donorFileErrors = [];
            this.donorFileOK = true;
            this.donorFileError = false;
            this.collection.collectionFacts = this.convertDonors(res.rows, schema);
            if (this.exceedSamples) {
              this.donorFileError = true;
              this.donorFileOK = false;
              this.donorFileErrors = [];
              this.handleErrors(res, this.donorFileErrors, schema);
            }

          }
          else {
            this.donorFileError = true;
            this.donorFileOK = false;
            this.donorFileErrors = [];
            this.handleErrors(res, this.donorFileErrors, schema);
          }

        });

      },
      resetDonor() {
        this.resetDonorOnSave();
        this.saveSuccess = false;
        this.deleteSuccess = false;
      },
      resetCollection() {
        this.resetCollectionOnSave();
        this.saveSuccess = false;
        this.deleteSuccess = false;
      },

      resetCollectionOnSave() {
        this.$refs.factCollectionFile.value = null;
        this.collectionFileError = false;
        this.collectionFileOK = false;
        this.collectionFileErrors = [];
        this.exceedSamples = false;
        // this.exceedDonors = false;
        this.totalSamples = 0;
        this.totalDonors = 0;

      },
      resetDonorOnSave() {
        this.$refs.factDonorFile.value = null;
        this.donorFileError = false;
        this.donorFileOK = false;
        this.donorFileErrors = [];
        this.exceedSamples = false;
        // this.exceedDonors = false;
        this.totalSamples = 0;
        this.totalDonors = 0;

      },
      pluralR(s) {
        return s === 1 ? "record" : "records";
      },

      noChanges() {
        // return !this.dirty;
        return !this.dirty && !this.anyOfFilesOK();
      },
      anyOfFilesOK() {
        return this.collectionFileOK || this.donorFileOK;
      },
      anyOfFilesError() {
        return this.collectionFileError || this.donorFileError;
      },
      canSubmit() {
        return this.anyOfFilesOK() && ! this.anyOfFilesError();
      },
      onChanged(isChanged) {
        console.log(isChanged);
        this.isChanged = isChanged;
      },

      saveCollectionWithFacts(){
        (patchCollection(this.collection))
            .then(() => {
              this.saveSuccess = true;
              this.deleteSuccess = false;
              this.totalFacts = this.collection.collectionFacts.length;
              this.resetCollectionOnSave();
              this.resetDonorOnSave();
              this.err = null;
              this.facts = this.collection.collectionFacts;
              this.collection.deleteFacts = false;
            })
            .catch(err => {
              this.err = err;
              this.saveSuccess = false;
            });
      },

      deleteFacts() {
        if (!confirm(`Deleting fact data for "${this.collection.name}". This cannot be undone.`))
          return;

        this.collection.deleteFacts = true;

        (patchCollection(this.collection))
            .then(() => {
              this.deleteSuccess = true;
              this.saveSuccess = false;
              this.totalFacts = 0;
              this.resetCollectionOnSave();
              this.resetDonorOnSave();
              this.err = null;
              this.facts = [];
              this.collection.deleteFacts = false;
            })
            .catch(err => {
              this.err = err;
              this.deleteSuccess = false;
              this.saveSuccess = false;
            });

      },

      checkTotals(rows) {
        let sumSamplesTotal = 0;
        let sumDonorsTotal = 0;
        this.totalFactsFromData = rows.length;
        rows.forEach(row => {
          sumDonorsTotal += row.number_of_donors;
          sumSamplesTotal += row.number_of_samples;
        });
        if (sumSamplesTotal > this.collection.size) {
          this.exceedSamples = true;
          this.totalSamples = sumSamplesTotal;
        }
        // if (sumDonorsTotal > this.collection.number_of_donors) {
        //   this.exceedDonors = true;
        //   this.totalDonors = sumDonorsTotal;
        // }

      },

      convertDonors(rows, schema) {
        let res = [];
        rows.forEach(row => {
          let range = null;
          Object.entries(this.ageRangeTypesMapping).forEach(([key, value]) => {
                let age = row.age;
                if ((! value.hasOwnProperty("min") || value.min <= age) &&
                    (! value.hasOwnProperty("max") || value.max >= age)) {
                  row["age_range"] = key;
                }
              }
          );
        });

        this.totalDonorRecords = rows.length;

        let group = Object.groupBy(rows, ({ sex, disease, sample_type, age_range }) =>
            sex+":"+disease+":"+sample_type+":"+age_range);

        let result = [];

        let sumSamplesTotal = 0;
        let sumDonorsTotal = 0;

        this.totalFactsFromDonors = Object.entries(group).length;

        Object.entries(group).forEach(([key, value]) => {
          let sumSamples = 0;
          let sumDonors = 0;
          value.forEach(v => {
            sumSamples += v.number_of_samples;
            sumDonors++;
          });
          result.push({
            sex: value[0].sex,
            age_range: value[0].age_range,
            sample_type: value[0].sample_type,
            disease: value[0].disease,
            number_of_samples: sumSamples,
            number_of_donors: sumDonors,
            },
          );
          sumSamplesTotal += sumSamples;
          sumDonorsTotal += sumDonors;
        });

        if (sumSamplesTotal > this.collection.size) {
          this.exceedSamples = true;
          this.totalSamples = sumSamplesTotal;
        }
        // if (sumDonorsTotal > this.collection.number_of_donors) {
        //   this.exceedDonors = true;
        //   this.totalDonors = sumDonorsTotal;
        // }

        return result;

      }
    },
}
</script>
